import { URLService } from "@/services/URLService";

/* POSSIBLE API CALL STATES */
const API_STATE = {
  PENDING: "pending",
  OK: "ok",
  FAIL_SERVER: "fail_server",
  FAIL: "fail"
};

/* PT STRINGS FOR USER FEEDBACK */
const STRINGS = {
  SERVER_ERROR: "Erro do servidor",
  REPORT_URL_SUCCESS: "Denúncia submetida com sucesso!",
  SEND_CONTACT_FORM_SUCCESS: "Mensagem enviada com sucesso."
};

////////////////////////////////////////////////////////////////////////////////

const apiCallAndDispatchState = (commit, dispatch, data, notify = true) => {
  commit(data.mutation, API_STATE.PENDING);

  return new Promise((resolve, reject) => {
    data.apiCall
      .then(res => {
        commit(data.mutation, API_STATE.OK);
        if (notify)
          dispatchNotification(
            dispatch,
            "success",
            data.category,
            data.successMessage
          );
        return resolve(res);
      })
      .catch(err => {
        if (!err || !err.response || !err.response.data) {
          commit(data.mutation, API_STATE.FAIL_SERVER);
          if (notify)
            dispatchNotification(
              dispatch,
              "error",
              data.category,
              STRINGS.SERVER_ERROR
            );
        } else {
          const userMessage = err.response.data.error;
          commit(data.mutation, API_STATE.FAIL);
          if (notify)
            dispatchNotification(dispatch, "error", data.category, userMessage);
        }

        return reject(err);
      });
  });
};

const dispatchNotification = (dispatch, status, category, message) => {
  const notification = {
    status: status,
    category: category,
    message: message
  };

  // this should come as argument (whether to timeout and when)
  if (category === "login" && status === "success") notification.timeout = 2000;

  dispatch("notification/add", notification, { root: true });
};

const defaultApiState = () => {
  return {
    status: "",
    timestamp: new Date(0)
  };
};

const defaultMutation = (state, category, status) => {
  state[category].status = status;
  state[category].timestamp = Date.now();
};

export const namespaced = true;

////////////////////////////////////////////////////////////////////////////////

export const state = {
  reportUrl: defaultApiState(),
  sendContactForm: defaultApiState(),
  getPublicSlugInfo: defaultApiState()
};

export const mutations = {
  REPORT_URL(state, status) {
    defaultMutation(state, "reportUrl", status);
  },
  SEND_CONTACT_FORM(state, status) {
    defaultMutation(state, "sendContactForm", status);
  },
  GET_PUBLIC_SLUG_INFO(state, status) {
    defaultMutation(state, "getPublicSlugInfo", status);
  }
};

export const actions = {
  reportUrl({ commit, dispatch }, payload) {
    const data = {
      apiCall: URLService.report_url(
        payload.motive,
        payload.slug,
        payload.comment,
        payload.recaptchaToken
      ),
      mutation: "REPORT_URL",
      category: "reportUrl",
      successMessage: STRINGS.REPORT_URL_SUCCESS
    };

    return apiCallAndDispatchState(commit, dispatch, data);
  },
  sendContactForm({ commit, dispatch }, payload) {
    const data = {
      apiCall: URLService.sendContactForm(
        payload.type,
        payload.name,
        payload.email,
        payload.subject,
        payload.message,
        payload.prevRoute,
        payload.recaptchaToken
      ),
      mutation: "SEND_CONTACT_FORM",
      category: "sendContactForm",
      successMessage: STRINGS.SEND_CONTACT_FORM_SUCCESS
    };

    return apiCallAndDispatchState(commit, dispatch, data);
  },
  getPublicSlugInfo({ commit, dispatch }, payload) {
    const data = {
      apiCall: URLService.getPublicSlugInfo(payload.slug),
      mutation: "GET_PUBLIC_SLUG_INFO",
      category: "getPublicSlugInfo"
    };

    return apiCallAndDispatchState(commit, dispatch, data, false);
  }
};

export const getters = {
  isPending: state => category => {
    return state[category].status === API_STATE.PENDING;
  }
};
