<template>
  <div>
    <button
      v-on="$listeners"
      v-bind="$attrs"
      :disabled="!isEnabled"
      :class="buttonClasses"
    >
      <template v-if="status === 'loading'">
        <div class="spinner-border" role="status"></div>
      </template>
      <template v-else>
        <slot />
      </template>
    </button>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    buttonExternalClasses: {
      type: String,
      default: ""
    },
    status: {
      type: String,
      default: "default"
    }
  },
  computed: {
    isEnabled() {
      return this.status === "default";
    },
    buttonClasses() {
      let base = "btn btn-lg btn-block";

      if (this.status === "disabled")
        return base + " btn-secondary " + this.buttonExternalClasses;
      else return base + " btn-primary " + this.buttonExternalClasses;
    }
  },
  methods: {}
};
</script>

<style scoped></style>
