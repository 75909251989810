<template>
  <div class="card card-border border-success animated fadeInDown mt-4">
    <div class="card-body m-n4">
      <h5 class="card-title">A tua miniatura clica.ai é:</h5>
      <div class="row">
        <div class="col-8 mr-auto">
          <p class="mb-1">
            <span>{{ namespace }}</span
            ><span class="font-weight-bold">{{ slug }}</span>
          </p>
          <p class="font-size-sm text-muted mb-0">
            {{ destination }}
          </p>
        </div>

        <div class="col-4">
          <BaseButton
            id="copyButton"
            buttonExternalClasses="copy-btn ml-4 btn-sm"
            :data-clipboard-text="namespace + slug"
            @click="showTooltip"
          >
            Copiar
          </BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClipboardJS from "clipboard";

export default {
  props: {
    namespace: {
      // must end in '/' to concatenate with slug
      type: String,
      required: true
    },
    slug: {
      type: String,
      required: true
    },
    destination: {
      type: String,
      required: true
    }
  },
  mounted() {
    // activate clipboard.js
    new ClipboardJS(".copy-btn");

    // activate Bootstrap tooltips
    // eslint-disable-next-line no-undef
    $("#copyButton").tooltip({
      title: "Copiado!",
      placement: "top",
      trigger: "manual"
    });
  },
  methods: {
    showTooltip() {
      // show tooltip and hide after 3 seconds
      // eslint-disable-next-line no-undef
      $("#copyButton").tooltip("show");

      setTimeout(() => {
        // eslint-disable-next-line no-undef
        $("#copyButton").tooltip("hide");
      }, 3000);
    }
  }
};
</script>

<style scoped></style>
