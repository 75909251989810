import Noty from "noty";
import "noty/lib/noty.css";
import "noty/lib/themes/relax.css";

export const namespaced = true;

export const state = {
  notifications: []
};

let nextId = 0;

export const mutations = {
  PUSH(state, notification) {
    state.notifications.push({
      ...notification,
      id: nextId++,
      timestamp: Date.now()
    });
  },
  DELETE(state, notificationId) {
    // hide the respective popup
    state.notifications.forEach(notification => {
      if (
        notification.id === notificationId &&
        notification.nElement &&
        !notification.nElement.closed
      )
        notification.nElement.close();
    });

    state.notifications = state.notifications.filter(notification => {
      return notification.id !== notificationId;
    });
  }
};

export const actions = {
  add({ commit }, notification) {
    if (!notification.message) {
      // do not show notification if message is empty
      commit("PUSH", notification);
    } else {
      const nElement = new Noty({
        theme: "relax",
        layout: "topLeft",
        closeWith: ["button", "click"],
        callbacks: {
          afterClose: (id => {
            return () => {
              commit("DELETE", id);
            };
          })(nextId)
        },
        //visibilityControl: true,
        text: notification.message,
        type: notification.status,
        timeout: notification.timeout || false
      });

      nElement.show();

      commit("PUSH", {
        ...notification,
        nElement: nElement
      });
    }
  },
  removeCategory({ commit, state }, payload) {
    state.notifications.forEach(n => {
      if (n.category === payload.category && !payload.toKeep.includes(n.status))
        commit("DELETE", n.id);
    });
  }
};

export const getters = {
  /*getNotificationsType: (state, type) => {
        return state.notifications.filter((notification) => {
            notification.type === type
        })
    }*/
};
