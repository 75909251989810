// Vue stuff
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";

// global components
import Vuelidate from "vuelidate";
import { InlineSvgPlugin } from "vue-inline-svg";

import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";

// global component registration
const requireComponent = require.context(
  "./components",
  false,
  /Base[A-Z]\w+\.(vue|js)$/
);

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName);
  const componentName = upperFirst(
    camelCase(
      fileName
        .split("/")
        .pop()
        .replace(/\.\w+$/, "")
    )
  );

  Vue.component(componentName, componentConfig.default || componentConfig);
});

Vue.config.productionTip = false;

Vue.use(Vuelidate);
Vue.use(InlineSvgPlugin);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
