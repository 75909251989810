import Vue from "vue";
import VueTypedJs from "vue-typed-js";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Blog from "./views/Blog.vue";
import ContactUs from "./views/ContactUs.vue";
import PageNotFound from "./views/PageNotFound.vue";
import BannedURL from "./views/BannedURL.vue";
import URLNotFound from "./views/URLNotFound.vue";
import SlugPublicInfo from "./views/SlugPublicInfo.vue";

import MainContent from "./views/MainContent.vue";

Vue.use(VueTypedJs);
Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: MainContent,
      children: [
        {
          path: "",
          name: "home",
          component: Home
        },
        {
          path: "blog",
          name: "blog",
          component: Blog
        },
        {
          path: "contacta-nos",
          name: "contact-us",
          component: ContactUs
        },
        {
          path: "preçário",
          alias: "precario",
          name: "pricing",
          component: () =>
            import(/* webpackChunkName: "about" */ "./views/Pricing.vue")
        },
        // HELP FOOTER CATEGORY
        {
          path: "instruções",
          name: "instructions",
          alias: "instrucoes",
          component: () =>
            import(/* webpackChunkName: "about" */ "./views/Instructions.vue")
        },
        {
          path: "denunciar",
          name: "report-url",
          alias: "reportar",
          component: () =>
            import(/* webpackChunkName: "about" */ "./views/ReportUrl.vue")
        },
        // GDPR FOOTER CATEGORY
        {
          path: "pedido-rgpd",
          name: "gdpr-request",
          component: () =>
            import(/* webpackChunkName: "about" */ "./views/GDPRRequest.vue")
        },
        // OUR FEATURES FOOTER CATEGORY
        {
          path: "miniaturas-personalizadas",
          name: "feature/custom-url",
          component: () =>
            import(
              /* webpackChunkName: "about" */ "./views/FeaturesCustomURL.vue"
            )
        },
        // ABOUT US FOOTER CATEGORY
        {
          path: "termos-e-condições",
          name: "terms-conditions",
          alias: "termos-e-condicoes",
          component: () =>
            import(
              /* webpackChunkName: "about" */ "./views/TermsConditions.vue"
            )
        },
        {
          path: "política-de-privacidade",
          name: "privacy-policy",
          alias: "politica-de-privacidade",
          component: () =>
            import(/* webpackChunkName: "about" */ "./views/PrivacyPolicy.vue")
        },
        {
          path: "o-nosso-serviço",
          name: "about",
          alias: "o-nosso-servico",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () =>
            import(/* webpackChunkName: "about" */ "./views/OurService.vue")
        },
        {
          path: "info/:slug",
          name: "info",
          component: SlugPublicInfo,
          props: true
        }
      ]
    },
    {
      path: "/ligação-suspensa",
      name: "banned-url",
      alias: "/ligacao-suspensa",
      component: BannedURL
    },
    {
      path: "/ligação-não-encontrada",
      name: "url-not-found",
      alias: "/ligacao-nao-encontrada",
      component: URLNotFound
    },
    // catch all route
    {
      path: "*",
      name: "404",
      component: PageNotFound
    }
  ]
});
