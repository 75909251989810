<template>
  <select
    :value="value"
    @change="updateValue"
    v-on="listeners"
    v-bind="$attrs"
    class="form-control custom-select"
    :class="computedClasses"
  >
    <option :value="null" disabled hidden>{{
      defaultOptionPlaceholder
    }}</option>
    <option
      v-for="option in options"
      :key="option.value"
      :value="option.value"
      :selected="option.value === value"
    >
      {{ option.text }}
    </option>
  </select>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    options: {
      type: Array,
      required: true
    },
    value: [String, Number],
    defaultOptionPlaceholder: {
      type: String,
      default: ""
    },
    additionalClasses: {
      type: String,
      default: ""
    },
    dirty: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    listeners() {
      return {
        // vue mastery S04E11, conflict between listeners
        ...this.$listeners,
        input: this.updateValue
      };
    },
    computedClasses() {
      let classes = this.additionalClasses;

      if (this.dirty)
        return this.error ? classes + " is-invalid" : classes + " is-valid";
      else return classes;
    }
  },
  methods: {
    updateValue(event) {
      this.$emit("input", event.target.value);
    }
  }
};
</script>
