<template lang="html">
  <section class="section-border border-primary">
    <div class="container d-flex flex-column">
      <div
        class="row align-items-center justify-content-center no-gutters min-vh-100"
      >
        <div class="col-12 col-md-5 col-lg-4 py-8 py-md-11">
          <h1 class="display-3 font-weight-bold text-center">
            URL Removido.
          </h1>

          <p class="mb-5 text-center text-muted">
            O URL que visitaste foi suspenso por infringir os nossos
            <router-link :to="{ name: 'terms-conditions' }"
              >Termos de Serviço</router-link
            >. Se achas que isto é um erro, por favor
            <router-link :to="{ name: 'contact-us' }">contacta-nos</router-link
            >.
          </p>

          <div class="text-center">
            <router-link class="btn btn-primary" :to="{ name: 'home' }"
              >Voltar à página principal</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="css"></style>
