<template>
  <div class="card card-border border-danger animated fadeInDown mt-4">
    <div class="card-body m-n4">
      <div class="row">
        <div class="col-12 mr-auto">
          <p class="mb-1">
            Não conseguimos minificar o link:
            <span class="font-weight-bold">{{ error }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped></style>
