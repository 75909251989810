<template>
  <section class="pt-4 pt-md-11">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12 col-md-5 col-lg-6 order-md-2">
          <img
            src="assets/img/illustrations/illustration-4.png"
            class="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0"
            alt="..."
            data-aos="fade-up"
            data-aos-delay="100"
          />
        </div>
        <div class="col-12 col-md-7 col-lg-6 order-md-1" data-aos="fade-up">
          <h1 class="display-5 text-center text-md-left">
            Apresentamos o <span class="text-primary">clica.ai</span>. <br />
            O gestor de links português.
          </h1>

          <p class="lead text-center text-md-left text-muted mb-6 mb-lg-8">
            Cria links para o teu site com um nome apelativo e memorável. Vê
            todas as estatísticas em tempo real.
          </p>

          <div class="text-center text-md-left">
            <p>Minifica o teu URL</p>
            <form @submit.prevent="createUrl">
              <div class="form-row">
                <div class="col-12 col-lg">
                  <div class="form-group mb-lg-0">
                    <BaseInput
                      id="url"
                      label="URL original"
                      placeholder="URL original"
                      v-model="url"
                      :dirty="!url && showNeededURL"
                      :error="!url && showNeededURL"
                    />

                    <BaseValidation
                      :messages="[
                        {
                          condition: !url && showNeededURL,
                          message: 'Tens de colar um URL aqui'
                        }
                      ]"
                    />
                  </div>
                </div>
                <div class="col-12 col-lg-auto">
                  <BaseButton
                    type="submit"
                    :status="buttonStatus"
                    buttonExternalClasses="shadow lift"
                  >
                    Criar URL
                    <i class="fe fe-arrow-right d-none d-md-inline ml-3"></i>
                  </BaseButton>
                </div>
              </div>
              <vue-recaptcha
                @verify="onCaptchaVerified"
                @expired="onCaptchaExpired"
                ref="createUrlRecaptcha"
                sitekey="6LdiVZ4UAAAAAJTYLaOXSUIJg3unfjHriXgPLgSL"
                size="invisible"
              ></vue-recaptcha>
            </form>
            <div class="row">
              <div class="col-md-12">
                <small id="legalese" class="form-text text-muted">
                  Ao minificar um URL estás a concordar com os nossos
                  <router-link :to="{ name: 'terms-conditions' }"
                    >Termos&nbsp;e&nbsp;Condições</router-link
                  >
                  e
                  <router-link :to="{ name: 'privacy-policy' }"
                    >Política&nbsp;de&nbsp;Privacidade</router-link
                  >.
                </small>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <CardCreateUrlSuccess
                  v-if="shortened.slug"
                  :namespace="shortened.namespace"
                  :slug="shortened.slug"
                  :destination="shortened.destination"
                />
                <CardCreateUrlError
                  v-else-if="shortened.errorMsg"
                  :error="shortened.errorMsg"
                />
              </div>
            </div>
            <!-- <a href="docs/index.html" class="btn btn-primary-soft lift">
                        Criar Conta
                    </a> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapState } from "vuex";
import VueRecaptcha from "vue-recaptcha";

import CardCreateUrlSuccess from "@/components/CardCreateUrlSuccess";
import CardCreateUrlError from "@/components/CardCreateUrlError";

export default {
  data() {
    return {
      url: "",
      buttonStatus: "default",
      showNeededURL: false
    };
  },
  components: {
    "vue-recaptcha": VueRecaptcha,
    CardCreateUrlSuccess,
    CardCreateUrlError
  },
  computed: {
    ...mapState(["shortened"])
  },
  methods: {
    createUrl() {
      if (this.url) {
        this.showNeededURL = false;
        this.$refs.createUrlRecaptcha.execute();
      } else {
        this.showNeededURL = true;
      }
    },
    onCaptchaExpired(err) {
      this.$refs.createUrlRecaptcha.reset();
    },
    async onCaptchaVerified(token) {
      this.buttonStatus = "loading";

      this.$refs.createUrlRecaptcha.reset();

      try {
        // perform api request
        const data = await this.$store.dispatch("shortenUrl", {
          url: this.url,
          token: token
        });
        this.buttonStatus = "default";
      } catch (err) {
        this.buttonStatus = "default";
      }
    }
  }
};
</script>

<style scoped></style>
