<template lang="html">
  <section class="section-border border-primary">
    <div class="container d-flex flex-column">
      <div
        class="row align-items-center justify-content-center no-gutters min-vh-100"
      >
        <div
          class="col-8 col-md-6 col-lg-7 offset-md-1 order-md-2 mt-auto mt-md-0 pt-8 pb-4 py-md-11"
        >
          <!-- Image -->
          <img
            src="assets/img/illustrations/illustration-1.png"
            alt="..."
            class="img-fluid"
          />
        </div>
        <div
          class="col-12 col-md-5 col-lg-4 order-md-1 mb-auto mb-md-0 pb-8 py-md-11"
        >
          <!-- Heading -->
          <h1 class="display-3 font-weight-bold text-center">
            Erro 404.
          </h1>

          <!-- Text -->
          <p class="mb-5 text-center text-muted">
            Não conseguimos encontrar a página que procura. Que tal voltar à
            página principal?
          </p>

          <!-- Link -->
          <div class="text-center">
            <router-link class="btn btn-primary" :to="{ name: 'home' }"
              >Voltar à página principal</router-link
            >
          </div>
        </div>
      </div>
      <!-- / .row -->
    </div>
    <!-- / .container -->
  </section>
</template>

<script>
export default {};
</script>

<style lang="css"></style>
