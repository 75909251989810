import axios from "axios";
const API_URL = "https://api.clica.ai";

const apiClient = axios.create({
  baseURL: API_URL,
  timeout: 8000,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

export const URLService = {
  create_url(long_url, token) {
    return apiClient.post("/urls", { url: long_url, token: token });
  },
  report_url(motive, slug, comment, recaptchaToken) {
    return apiClient.post("/report", {
      slug: slug,
      motive: motive,
      comment: comment,
      recaptchaToken: recaptchaToken
    });
  },
  sendContactForm(
    type,
    name,
    email,
    subject,
    message,
    prevRoute,
    recaptchaToken
  ) {
    return apiClient.post("/contact-us", {
      type: type,
      name: name,
      email: email,
      subject: subject,
      message: message,
      prevRoute: prevRoute,
      recaptchaToken: recaptchaToken
    });
  },
  getPublicSlugInfo(slug) {
    return apiClient.get("/urls/" + slug + "/info", { slug });
  }
};
