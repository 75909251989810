<template>
  <div class="">
    <!-- WELCOME
        ================================================== -->
    <WelcomeSection />

    <!-- FEATURES
        ================================================== -->
    <section class="py-8 py-md-11 border-bottom">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-4" data-aos="fade-up">
            <!-- Icon -->
            <div class="icon text-primary mb-3">
              <inline-svg
                src="assets/img/icons/duotone-icons/Layout/Layout-arrange.svg"
              ></inline-svg>
            </div>

            <!-- Heading -->
            <h3>
              Estatísticas incluídas
            </h3>

            <!-- Text -->
            <p class="text-muted mb-6 mb-md-0">
              O clica.ai permite-te conhecer melhor a tua audiência através de
              gráficos detalhados sobre cada clique individual. Só precisas de
              criar uma conta.
            </p>
          </div>
          <div class="col-12 col-md-4" data-aos="fade-up" data-aos-delay="50">
            <!-- Icon -->
            <div class="icon text-primary mb-3">
              <inline-svg
                src="assets/img/icons/duotone-icons/General/Settings-1.svg"
              ></inline-svg>
            </div>

            <!-- Heading -->
            <h3>
              Totalmente personalizável
            </h3>

            <!-- Text -->
            <p class="text-muted mb-6 mb-md-0">
              Define destinos diferentes para Android e iOS ou cria um URL com o
              nome da tua marca. Ao personalizares os teus links vais
              distinguir-te da concorrência.
            </p>
          </div>
          <div class="col-12 col-md-4" data-aos="fade-up" data-aos-delay="100">
            <!-- Icon -->
            <div class="icon text-primary mb-3">
              <inline-svg
                src="assets/img/icons/duotone-icons/Code/Code.svg"
              ></inline-svg>
            </div>

            <!-- Heading -->
            <h3>
              Funcionalidades avançadas
            </h3>

            <!-- Text -->
            <p class="text-muted mb-0">
              Se já tiveres um domínio que represente melhor a tua marca (ex.
              empre.sa), o clica.ai permite-te criar links usando o teu próprio
              domínio.
            </p>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .container -->
    </section>

    <!-- ABOUT
        ================================================== -->
    <section class="pt-7 pt-md-10 pb-13 bg-gradient-light">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12 col-md-7 col-lg-6" data-aos="fade-right">
            <!-- Heading -->
            <h2>
              Temos experiência<br />
              <span class="text-primary">em que podes confiar</span>.
            </h2>

            <!-- Text -->
            <p class="font-size-lg text-muted mb-6">
              Confiamos no serviço que construimos e oferecemos garantias de
              disponibilidade aos nossos clientes.
            </p>

            <!-- List -->
            <div class="d-flex">
              <!-- Icon -->
              <div class="icon text-primary">
                <inline-svg
                  src="assets/img/icons/duotone-icons/Media/Repeat.svg"
                ></inline-svg>
              </div>

              <div class="ml-5">
                <!-- Heading -->
                <h4 class="mb-1">
                  Proteção contra falhas
                </h4>

                <!-- Text -->
                <p class="text-muted mb-6">
                  O nosso serviço corre na nuvem da Amazon Web Services e possui
                  vários mecanismos de proteção contra falhas.
                </p>
              </div>
            </div>
            <div class="d-flex">
              <!-- Icon -->
              <div class="icon text-primary">
                <inline-svg
                  src="assets/img/icons/duotone-icons/Code/Code.svg"
                ></inline-svg>
              </div>

              <div class="ml-5">
                <!-- Heading -->
                <h4 class="mb-1">
                  Cumprimos com a RGPD
                </h4>

                <!-- Text -->
                <p class="text-muted mb-6 mb-md-0">
                  Tomamos várias medidas para garantir que apenas armazenamos e
                  usamos informação anonimizada. Não partilhamos dados com
                  terceiros.
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-5 col-lg-6">
            <div
              class="w-md-150 w-lg-130 position-relative"
              data-aos="fade-left"
            >
              <!-- Shape -->
              <div
                class="shape shape-fluid-y shape-blur-4 svg-shim text-gray-200"
              >
                <inline-svg
                  src="assets/img/shapes/blurs/blur-4.svg"
                ></inline-svg>
              </div>

              <!-- Image -->
              <div class="img-skewed img-skewed-left">
                <!-- Image -->
                <img
                  src="assets/img/screenshots/desktop/dashkit.png"
                  class="screenshot img-fluid img-skewed-item"
                  alt="..."
                />
              </div>
            </div>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .container -->
    </section>

    <!-- TESTIMONIALS
        ================================================== -->
    <!-- <TestimonialsSection /> -->

    <!-- SHAPE
        ================================================== -->
    <div class="position-relative mt-n8">
      <div class="shape shape-bottom shape-fluid-x svg-shim text-gray-200">
        <inline-svg src="assets/img/shapes/curves/curve-3.svg"></inline-svg>
      </div>
    </div>

    <!-- STATS
        ================================================== -->
    <section class="pt-6 pt-md-6 bg-gray-200 mb-15">
      <div class="container">
        <div class="row align-items-center pb-10">
          <div class="col-12 col-md-5 col-lg-6 order-md-2">
            <!-- Image -->
            <img
              src="assets/img/illustrations/illustration-8.png"
              alt="..."
              class="img-fluid mb-6 mb-md-0"
            />
          </div>
          <div class="col-12 col-md-7 col-lg-6 order-md-1">
            <!-- Heading -->
            <h2>
              Foca-te no teu negócio. <br />
              <span class="text-primary">Nós tratamos dos teus links</span>.
            </h2>

            <!-- Text -->
            <p class="font-size-lg text-gray-700 mb-6">
              O clica.ai faz uma análise dos teus resultados e dá-te a conhecer
              várias estatísticas sobre o teu público-alvo.
            </p>

            <!-- Stats -->
            <div class="d-flex">
              <div class="pr-5">
                <h3 class="mb-0">
                  <span
                    data-toggle="countup"
                    data-from="700000"
                    data-to="794000"
                    data-aos
                    data-aos-id="countup:in"
                  ></span
                  >+
                </h3>
                <p class="text-gray-700 mb-0">
                  Cliques
                </p>
              </div>
              <div class="border-left border-gray-300"></div>
              <div class="pl-5 pr-5">
                <h3 class="mb-0">
                  <span
                    data-toggle="countup"
                    data-from="7000"
                    data-to="7100"
                    data-aos
                    data-aos-id="countup:in"
                  ></span
                  >+
                </h3>
                <p class="text-gray-700 mb-0">
                  Links
                </p>
              </div>
              <div class="border-left border-gray-300"></div>
              <div class="pl-5">
                <h3 class="mb-0">
                  <span
                    data-toggle="countup"
                    data-from="999"
                    data-to="60"
                    data-aos
                    data-aos-id="countup:in"
                  ></span
                  >ms
                </h3>
                <p class="text-gray-700 mb-0">
                  Tempo de resposta
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .container -->
    </section>

    <!-- PRICING
        ================================================== -->

    <!-- SHAPE
        ================================================== -->
    <div class="position-relative mt-n15">
      <div class="shape shape-bottom shape-fluid-x svg-shim text-dark">
        <inline-svg src="assets/img/shapes/curves/curve-1.svg"></inline-svg>
      </div>
    </div>

    <!-- FAQ
        ================================================== -->
    <section class="bg-dark">
      <div class="container pt-8 pt-md-11">
        <div class="row">
          <div class="col-12 col-md-6">
            <!-- Item -->
            <div class="d-flex">
              <!-- Badge -->
              <div class="badge badge-lg badge-rounded-circle badge-success">
                <span>?</span>
              </div>

              <div class="ml-5">
                <!-- Heading -->
                <h4 class="text-white">
                  Posso usar o clica.ai para os meus clientes?
                </h4>

                <!-- Text -->
                <p class="text-muted mb-6 mb-md-8">
                  Sim! Podes criar URLs para qualquer outro site desde que o
                  mesmo apenas contenha conteúdo alinhado com os nossos
                  <router-link :to="{ name: 'terms-conditions' }"
                    >termos e condições</router-link
                  >.
                </p>
              </div>
            </div>

            <!-- Item -->
            <div class="d-flex">
              <!-- Badge -->
              <div class="badge badge-lg badge-rounded-circle badge-success">
                <span>?</span>
              </div>

              <div class="ml-5">
                <!-- Heading -->
                <h4 class="text-white">
                  O que é que a versão paga me oferece?
                </h4>

                <!-- Text -->
                <p class="text-muted mb-6 mb-md-0">
                  Os nossos clientes da versão paga podem ver mais informação
                  dos URLs que criaram: o sistema operativo, zona geográfica dos
                  utilizadores, as horas e dias mais populares, e muito mais.
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <!-- Item -->
            <div class="d-flex">
              <!-- Badge -->
              <div class="badge badge-lg badge-rounded-circle badge-success">
                <span>?</span>
              </div>

              <div class="ml-5">
                <!-- Heading -->
                <h4 class="text-white">
                  Posso escolher o nome do meu URL pequeno?
                </h4>

                <!-- Text -->
                <p class="text-muted mb-6 mb-md-8">
                  Sim, oferecemos esse serviço como um extra. Paga um valor fixo
                  pelo URL (apenas uma vez) e mantém a propriedade do mesmo
                  indefinidamente.
                </p>
              </div>
            </div>

            <!-- Item -->
            <div class="d-flex">
              <!-- Badge -->
              <div class="badge badge-lg badge-rounded-circle badge-success">
                <span>?</span>
              </div>

              <div class="ml-5">
                <!-- Heading -->
                <h4 class="text-white">
                  Posso passar da versão grátis para a versão paga?
                </h4>

                <!-- Text -->
                <p class="text-muted mb-6 mb-md-0">
                  A qualquer altura pode trocar da versão grátis para a paga e
                  vice-versa. Caso já tenha URLs criados, poderá ver mais
                  informação sobre eles, mesmo que tenham sido criados antes de
                  mudar para a versão paga.
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .container -->
    </section>

    <!-- CTA
        ================================================== -->
    <section class="py-8 py-md-11 bg-dark">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-8 text-center">
            <!-- Badge -->
            <span class="badge badge-pill badge-gray-700-soft mb-4">
              <span class="h6 font-weight-bold text-uppercase"
                >Começa a usar</span
              >
            </span>

            <!-- Heading -->
            <h1 class="display-4 text-white">
              Regista-te e começa a partilhar.
            </h1>

            <!-- Text -->
            <p class="font-size-lg text-muted mb-6 mb-md-8">
              O clica.ai personaliza e acompanha as tuas campanhas, oferece
              estatísticas detalhadas de cada clique e garante a privacidade dos
              teus utilizadores.<br />
              Começa hoje a partilhar links e conhece melhor o teu público-alvo.
            </p>

            <!-- Button -->
            <a href="https://app.clica.ai" class="btn btn-success lift">
              Criar conta <i class="fe fe-arrow-right"></i>
            </a>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .container -->
    </section>
  </div>
</template>

<script>
import WelcomeSection from "@/components/home/WelcomeSection";

export default {
  data() {
    return {
      url: ""
    };
  },
  created() {},
  components: {
    WelcomeSection
  },
  computed: {},
  methods: {},
  filters: {
    formatNumber(val) {
      if (val >= 1000000) {
        val = Math.floor(val / 1000000) * 1000000; // remove remainder
      } else if (val >= 1000) {
        val = Math.floor(val / 1000) * 1000; // remove remainder
      } else if (val >= 100) {
        val = Math.floor(val / 100) * 100;
      } else if (val >= 10) {
        val = Math.floor(val / 10) * 10;
      }
      return val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }
  }
};
</script>

<style scoped></style>
