<template>
  <section class="pt-8 pt-md-11 pb-8 pb-md-14">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10 col-lg-8 text-center">
          <h2 class="font-weight-bold">
            Fala Connosco!
          </h2>

          <p class="font-size-lg text-muted mb-7 mb-md-9">
            Estamos sempre disponíveis para ouvir as tuas sugestões e
            comentários! Ajuda-nos a saber como podemos melhorar o nosso
            serviço.
          </p>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 col-md-12 col-lg-10">
          <form @submit.prevent="sendContactForm">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group mb-5">
                  <BaseInput
                    id="name"
                    placeholder="Nome Completo"
                    v-model.trim="contact.name"
                    type="text"
                    @blur="$v.contact.name.$touch()"
                    :dirty="$v.contact.name.$dirty"
                    :error="$v.contact.name.$error"
                  />
                  <BaseValidation :messages="getValidationObjects().name" />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group mb-5">
                  <BaseInput
                    id="email"
                    placeholder="Email"
                    v-model.trim="contact.email"
                    type="email"
                    @blur="$v.contact.email.$touch()"
                    :dirty="$v.contact.email.$dirty"
                    :error="$v.contact.email.$error"
                  />
                  <BaseValidation :messages="getValidationObjects().email" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group mb-7 mb-md-9">
                  <label for="message">Em que podemos ajudar?</label>
                  <BaseTextArea
                    id="message"
                    placeholder="Descreve o motivo do teu contacto"
                    v-model.trim="contact.message"
                    @blur="$v.contact.message.$touch()"
                    :dirty="$v.contact.message.$dirty"
                    :error="$v.contact.message.$error"
                    :resizable="false"
                  />
                  <BaseValidation :messages="getValidationObjects().message" />
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-auto">
                <vue-recaptcha
                  @verify="onCaptchaVerified"
                  @expired="onCaptchaExpired"
                  ref="contactUsRecaptcha"
                  sitekey="6LdlMr8UAAAAAGWxbV1PCaP1xh_aUS6WnB_swIOj"
                  size="invisible"
                ></vue-recaptcha>
                <BaseButton
                  type="submit"
                  :status="buttonStatus"
                  buttonExternalClasses="lift"
                  >Enviar mensagem</BaseButton
                >
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapGetters } from "vuex";
import { required, email } from "vuelidate/lib/validators";

import VueRecaptcha from "vue-recaptcha";

export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from.name;
    });
  },
  components: {
    "vue-recaptcha": VueRecaptcha
  },
  data() {
    return {
      contact: this.getFreshMessageObject(),
      prevRoute: null
    };
  },
  computed: {
    ...mapGetters({
      pending: "api/isPending"
    }),
    isMessagePending() {
      // get login api status for button feedback
      return this.pending("sendContactForm");
    },
    buttonStatus() {
      return this.$v.$invalid
        ? "disabled"
        : this.isMessagePending
        ? "loading"
        : "default";
    }
  },
  methods: {
    getFreshMessageObject() {
      return {
        name: "",
        email: "",
        message: ""
      };
    },
    getValidationObjects() {
      return {
        name: [
          {
            condition: !this.$v.contact.name.required,
            message: "Nome obrigatório"
          }
        ],
        email: [
          {
            condition: !this.$v.contact.email.required,
            message: "Email obrigatório"
          },
          {
            condition: !this.$v.contact.email.email,
            message: "Email inválido"
          }
        ],
        message: [
          {
            condition: !this.$v.contact.message.required,
            message: "Conteúdo da mensagem obrigatório"
          }
        ]
      };
    },
    sendContactForm() {
      this.$v.$touch(); // makes the form dirty to show errors
      if (this.$v.$pending || this.$v.$error || this.$v.$invalid) return;

      this.$refs.contactUsRecaptcha.execute();
    },
    onCaptchaExpired(err) {
      this.$refs.contactUsRecaptcha.reset();
    },
    onCaptchaVerified(recaptchaToken) {
      this.$refs.contactUsRecaptcha.reset();

      this.$store
        .dispatch("api/sendContactForm", {
          type: "contact",
          name: this.contact.name,
          email: this.contact.email,
          message: this.contact.message,
          prevRoute: this.prevRoute,
          recaptchaToken: recaptchaToken
        })
        .then(res => {
          this.contact = this.getFreshMessageObject();
          this.$v.$reset();
        })
        .catch(err => {});
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  validations: {
    contact: {
      name: {
        required
      },
      email: {
        required,
        email
      },
      message: {
        required
      }
    }
  }
};
</script>

<style lang="css"></style>
