<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-white">
      <div class="container">
        <!-- Brand -->
        <router-link class="navbar-brand" :to="{ name: 'home' }">
          <img
            src="/assets/img/logo.svg"
            class="navbar-brand-img"
            alt="logo clica ai"
            height="86px"
          />
          clica.ai
        </router-link>

        <!-- Toggler -->
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <!-- Collapse -->
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <!-- Toggler -->
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i class="fe fe-x"></i>
          </button>

          <!-- Navigation -->
          <!-- <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'pricing' }">Preçário</router-link>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" :to="{ name: 'tutorial' }">Como Funciona?</router-link>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" :to="{ name: 'help-landing' }">Ajuda</router-link>
                </li>
              </ul> -->

          <!-- Button -->
          <template>
            <a
              class="navbar-btn btn btn-sm btn-primary lift ml-auto"
              href="https://app.clica.ai"
              >Entrar</a
            >
          </template>
        </div>
      </div>
    </nav>

    <router-view />

    <!-- SHAPE
        ================================================== -->
    <div class="position-relative">
      <div class="shape shape-bottom shape-fluid-x svg-shim text-gray-200">
        <svg
          viewBox="0 0 1440 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
        >
          <path d="M0 24H1440V0C722.5 52 0 0 0 0V24Z" fill="currentColor" />
        </svg>
      </div>
    </div>

    <!-- FOOTER
        ================================================== -->
    <footer class="py-8 py-md-11 bg-gray-200">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-4 col-lg-3">
            <!-- Brand -->
            <img
              src="/assets/img/logo.svg"
              alt="logo clica ai"
              class="footer-brand img-fluid mb-2"
              width="22px"
            />

            <!-- Text -->
            <p class="text-gray-700 mb-2">
              clica.ai
            </p>
            <p class="text-gray-700 mb-2">
              O gestor de links português.
            </p>

            <!-- Social -->
            <!-- <ul class="list-unstyled list-inline list-social mb-6 mb-md-0">
                  <li class="list-inline-item list-social-item mr-3">
                    <a href="#!" class="text-decoration-none">
                      <img src="/assets/img/icons/social/instagram.svg" class="list-social-icon" alt="...">
                    </a>
                  </li>
                  <li class="list-inline-item list-social-item mr-3">
                    <a href="#!" class="text-decoration-none">
                      <img src="/assets/img/icons/social/facebook.svg" class="list-social-icon" alt="...">
                    </a>
                  </li>
                  <li class="list-inline-item list-social-item mr-3">
                    <a href="#!" class="text-decoration-none">
                      <img src="/assets/img/icons/social/twitter.svg" class="list-social-icon" alt="...">
                    </a>
                  </li>
                  <li class="list-inline-item list-social-item">
                    <a href="#!" class="text-decoration-none">
                      <img src="/assets/img/icons/social/pinterest.svg" class="list-social-icon" alt="...">
                    </a>
                  </li>
                </ul> -->
          </div>
          <div class="col-6 col-md-4 col-lg-2">
            <!-- Heading -->
            <h6 class="font-weight-bold text-uppercase text-gray-700">
              Contactos
            </h6>

            <!-- List -->
            <ul class="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0">
              <li class="mb-3">
                <router-link :to="{ name: 'report-url' }" class="text-reset">
                  Reportar URL
                </router-link>
              </li>
              <li class="mb-3">
                <router-link :to="{ name: 'contact-us' }" class="text-reset">
                  Suporte
                </router-link>
              </li>
              <!-- <li class="mb-3">
                    <a href="#!" class="text-reset">
                      Orçamentos
                    </a>
                  </li> -->
              <li class="mb-3">
                <router-link :to="{ name: 'gdpr-request' }" class="text-reset">
                  RGPD
                </router-link>
              </li>
              <!-- <li>
                    <a href="#!" class="text-reset">
                      Segurança
                    </a>
                  </li> -->
            </ul>
          </div>
          <div class="col-6 col-md-4 col-lg-2">
            <!-- Heading -->
            <h6 class="font-weight-bold text-uppercase text-gray-700">
              Serviços
            </h6>

            <!-- List -->
            <ul class="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0">
              <li class="mb-3">
                <router-link
                  :to="{ name: 'feature/custom-url' }"
                  class="text-reset"
                >
                  Miniaturas Personalizadas
                </router-link>
              </li>
              <!-- <li class="mb-3">
                    <a href="#!" class="text-reset">
                      Domínios Personalizados
                    </a>
                  </li>
                  <li class="mb-3">
                    <a href="#!" class="text-reset">
                      URLs Dinâmicos
                    </a>
                  </li>
                  <li>
                    <a href="#!" class="text-reset">
                      Estatísticas Avançadas
                    </a>
                  </li> -->
            </ul>
          </div>
          <div class="col-6 col-md-4 offset-md-4 col-lg-2 offset-lg-0">
            <!-- Heading -->
            <h6 class="font-weight-bold text-uppercase text-gray-700">
              Informação Útil
            </h6>

            <!-- List -->
            <ul class="list-unstyled text-muted mb-0">
              <!-- <li class="mb-3">
                    <router-link :to="{ name: 'pricing'}" class="text-reset">
                      Preçário
                    </router-link>
                  </li> -->
              <li class="mb-3">
                <router-link
                  :to="{ name: 'terms-conditions' }"
                  class="text-reset"
                >
                  Termos de Serviço
                </router-link>
              </li>
              <li class="mb-3">
                <router-link
                  :to="{ name: 'terms-conditions' }"
                  class="text-reset"
                >
                  Conteúdo Aceitável
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'privacy-policy' }"
                  class="text-reset"
                >
                  Política de Privacidade
                </router-link>
              </li>
            </ul>
          </div>
          <div class="col-6 col-md-4 col-lg-2">
            <!-- Heading -->
            <h6 class="font-weight-bold text-uppercase text-gray-700">
              Links Úteis
            </h6>

            <!-- List -->
            <ul class="list-unstyled text-muted mb-0">
              <li class="mb-3">
                <a
                  href="https://app.clica.ai/pedir-recuperacao-palavra-passe"
                  class="text-reset"
                >
                  Repor Palavra-passe
                </a>
              </li>
              <li class="mb-3">
                <a href="https://app.clica.ai/" class="text-reset">
                  O Meu Painel
                </a>
              </li>
              <li>
                <a href="https://app.clica.ai/" class="text-reset">
                  Criar URL
                </a>
              </li>
            </ul>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .container -->
    </footer>
  </div>
</template>
<script>
export default {};
</script>
<style>
.grecaptcha-badge {
  visibility: collapse !important;
}
</style>
