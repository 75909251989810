import Vue from "vue";
import Vuex from "vuex";

import { URLService } from "@/services/URLService";

import * as api from "@/store/modules/api.js";
import * as notification from "@/store/modules/notification.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    api,
    notification
  },
  state: {
    shortened: {
      namespace: "",
      slug: "",
      destination: "",
      errorMsg: ""
    },
    user: null
  },
  mutations: {
    SHORTEN_URL(state, payload) {
      state.shortened = {
        namespace: payload.namespace,
        slug: payload.slug,
        destination: payload.destination
      };
    },
    SET_SHORTEN_ERR_MSG(state, payload) {
      state.shortened.errorMsg = payload;
    },
    CLEAN_SHORTENED(state) {
      state.shortened = {
        namespace: "",
        slug: "",
        destination: "",
        errorMsg: ""
      };
    }
  },
  actions: {
    shortenUrl({ commit }, payload) {
      commit("CLEAN_SHORTENED");
      return URLService.create_url(payload.url, payload.token)
        .then(res => {
          commit("SHORTEN_URL", { destination: payload.url, ...res.data.body });
          return Promise.resolve();
        })
        .catch(err => {
          commit(
            "SET_SHORTEN_ERR_MSG",
            err.response ? err.response.data.error : "Erro do servidor"
          );
          return Promise.reject();
        });
    }
  },
  getters: {}
});
