<template>
  <div>
    <section
      data-jarallax
      data-speed=".8"
      class="py-12 py-md-15 bg-cover jarallax"
      style="background-image: url(/assets/img/covers/cover-porto.jpg);"
    ></section>

    <section class="pt-8 pt-md-11">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-9 col-xl-8">
            <h1 class="display-4 text-center">
              Informação do URL
            </h1>

            <p v-if="!loading" class="lead mb-7 text-center text-muted">
              https://{{ namespace }}/{{ slug }}
              <i class="fe fe-arrow-right"></i> {{ destination }}
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import NProgress from "nprogress";

export default {
  props: {
    slug: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      loading: true,
      namespace: "clica.ai",
      destination: null
    };
  },
  async mounted() {
    window.scrollTo(0, 0);

    NProgress.start();

    try {
      const res = await this.$store.dispatch("api/getPublicSlugInfo", {
        slug: this.slug
      });
      this.destination = res.data.body.destination;

      NProgress.done();
      this.loading = false;
    } catch (err) {
      //console.log("error from server...");
    }
  }
};
</script>

<style lang="css"></style>
